.body-not-found {
    display: flex;
    height: 100vh;
    align-items: center;
}

.body-not-found .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 20px;
    border-radius: 10px;
}

.body-not-found h1 {
    font-size: 50px;
    margin-bottom: 30px;
}

.body-not-found p {
    font-size: 24px;
    margin-bottom: 50px;
}

.body-not-found a {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
}