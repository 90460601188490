@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

.carousel-container {
  height: 100vh;
  position: relative;

  .carousel-root {
    height: 100%;

    div.carousel.carousel-slider {
      height: 100%;

      .slider-wrapper {
        height: 100%;
        ul {
          height: 100%;
        }
      }

      .slide > div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: inherit;
        background-size: inherit;
        filter: blur(15px); /* Adjust the blur radius as needed */
        z-index: 1;
      }

      .slide > div {
        height: 100%;

        img {
          object-fit: contain;
          height: 100%;
          position: relative;
          z-index: 2;
        }
      }
    }

  }
}

.progress-bar-container {
  position: relative;
  width: 100%;
}

.progress-bar {

  width: 80% !important;
  position: absolute;
  bottom: 25px;
  display: flex;
  align-content: center;
  z-index: 15;
  overflow: hidden;
  // left: 50px;
  padding-left: 100px;
  // padding-bottom: 5px;

  .range {
    position: relative;
    background-color: #333;
    width: 100%;
    height: 30px;
    transform: skew(30deg);
    font-family: 'Orbitron', monospace;
    
    &:before {
      --width: calc(var(--p) * 1%);
      
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #F3E600;
      z-index: 0;
      animation: load .5s forwards linear; //, glitch 2s infinite linear;
    }
    
    &:after {
      content: var(--p) '%';  // Directly use the --p variable for content
      color: #000;
      position: absolute;
      left: 5%;
      top: 50%;
      transform: translateY(-50%) skewX(-30deg);
      z-index: 1;
    }
    
    &__label {
      transform: skew(-30deg) translateY(-100%);
      line-height: 1.5;
    }
  }
  
  @keyframes load {
    to {
      width: var(--width);
    }
  }
  
  @keyframes glitch {
    0%, 5% {
      transform: translate(0, 0);
    }
    1% {
      transform: translate(-5%, -10%);
    }
    2% {
      transform: translate(10%, 30%);
    }
    3%{
      transform: translate(-43%, 10%);
    }
    4% {
      transform: translate(2%, -23%);
    }
  }
}

@keyframes blur {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(10px); /* Adjust the blur radius as needed */
  }
}

.loading-container{
  background-color: #000;
}

video {
  /* override other styles to make responsive */
  width: 100% !important;
  // // height: auto !important;
  height: 95vh;
}

#carte {
  position: absolute;
  top: 10%;
  left: 39%;
  z-Index: 15;
  padding: 5px;
  font-size: 34px;
  font-family: 'Liberation Sans', sans-serif;
}

#carteMobile {
  position: absolute;
  top: 5%;
  left: 29%;
  z-Index: 15;
  padding: 5px;
  font-size: 30px;
  font-family: 'Liberation Sans', sans-serif;
}

.fullscreen-icon {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  top: 30%;
  left: 3%;
}